@import "src/assets/palette";

body {
  background-color: $background-color;
}

.AppContainer {
  width: 100%;
  text-align: center;
}


