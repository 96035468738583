@import "src/assets/palette";

.Header {

  position: relative;
  text-align: center;
  border-bottom: 1px solid #E1E1E1;
  background-color: $secondary-background-color;

  .Header_Container {

    padding: 40px 0;

    .Header_Container_Logo {
      width: 128px;
      border: 0;
    }

  }

  .Header_Login_Button {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    text-decoration: none;
    font-size: 0.8em;
    margin: 12px;
    border: 1px solid $accent-color;
    border-radius: 12px;
    padding: 8px 12px;
    color: $accent-color;
    font-variant: all-small-caps;
  }

  .Header_Login_Button:hover {
    border-color: $accent-color-dark;
    color: $accent-color-dark;
  }

}

@media(min-width: 800px) {

  .Header_Container {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
  }

}