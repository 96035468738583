@import "src/assets/palette";

.Menu {

  text-align: center;
  background-color: $secondary-background-color;
  padding: 8px 0;


  .Menu_Item {
    display: block;
    font-variant: all-small-caps;
    color: $text-color;
    padding: 10px 12px;
    margin: 0 10px;
    font-size: 1em;
    text-decoration: none;

    &:hover {
      color: $accent-color;
    }

    &.inactive {
      color: $outline-color;

      &:hover {
        color: $outline-color;
      }
    }

  }
}

.Menu_Button {
  background-color: $secondary-background-color;
  text-align: right;
  padding: 8px 12px;

  .Menu_Button_Hamburger {
    width: 32px;
    height: 32px;
  }
}

@media(min-width: 800px) {

  .Menu {

    display: flex;
    justify-content: center;

  }

  .Menu_Button {
    display: none;
  }
}
