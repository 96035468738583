@import "src/assets/palette";

.grades_container {

  display: block;
  margin: 18px auto 0;

  .grades_content {
    display: block;
    text-align: left;
    padding: 12px;
    background-color: $secondary-background-color;
    box-shadow: 2px 2px 5px $shadow-color;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;

    h1 {
      font-size: 1.1em;
      font-family: sans-serif;
      font-weight: bold;
      margin: 12px;
    }

    table {
      border-collapse: collapse;
      margin-top: 28px;
      margin-left:auto;
      margin-right:auto;

      tr th {
        padding: 12px;
        border-bottom: 1px solid #e1e1e1;
      }

      tr:hover {
        background: #f6f6f6;
      }

      tr td {
        border-bottom: 1px solid #ddd;
        padding: 12px 8px;
        width: 140px;
      }
    }
  }

}

@media(min-width: 800px) {

  .grades_container {
    display: flex;
    justify-content: center;
    max-width: 1200px;

    .grades_content {
      padding: 12px;
      flex: 1;
      text-align: center;

    }

  }

}