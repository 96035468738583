@import "./assets/palette";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.content_container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-top: 16px;
  padding: 12px;
  max-width: 85%;
  background-color: $secondary-background-color;
  box-shadow: 2px 2px 5px $shadow-color;
}

@media(min-width: 800px) {

  .content_container {

    &.narrow {
      max-width: 480px;
    }
  }

}