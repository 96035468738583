@import "src/assets/palette";

.home_container {

  display: block;
  margin: 18px auto 0;

  .home_content {
    display: block;
    text-align: left;
    padding: 12px;
    background-color: $secondary-background-color;
    box-shadow: 2px 2px 5px $shadow-color;
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;

    &.home_my_list {
      margin-top: 18px;
    }
  }

}

@media(min-width: 800px) {

  .home_container {
    display: flex;
    justify-content: center;
    max-width: 1200px;

    h1 {
      font-size: 1.1em;
      font-family: sans-serif;
      font-weight: bold;
      margin: 12px;
    }

    .home_content {

      padding: 12px;

      &.home_right_container {
        flex: 2;
      }

      &.home_my_list {
        flex: 1;
        margin-top: 0;
        margin-left: 12px;
      }
    }

  }

}